import React from 'react';
import { Link } from 'react-router-dom';
import './Riddle.css';

export const Riddle = (): JSX.Element => {
    return (
        <div className="Riddle">
            <h1 className="title">Birthday Riddle</h1>
            <p className="subtitle">Good luck!</p>
            <p className="riddle-description">The only way to find my last gift is to solve this birthday riddle. Let me know when you finish, and good luck:</p>

            <div className="riddle-block">
                <p className="riddle-paragraph">
                    <p>
                        <p className="riddle-line">I know that Max loves you</p>
                        <p className="riddle-line">That’s why he left me behind</p>
                        <p className="riddle-line">So if you follow these hints</p>
                        <p className="riddle-line">It’s me that you’ll find</p>
                    </p>
                    <p>
                        <p className="riddle-line">I’m rectangular and blue</p>
                        <p className="riddle-line">And near paper thin</p>
                        <p className="riddle-line">Unfold me you’ll find</p>
                        <p className="riddle-line">Your gift hidden within</p>
                    </p>
                    <p>
                        <p className="riddle-line">No need to look high</p>
                        <p className="riddle-line">You can start your search low</p>
                        <p className="riddle-line">But don’t look on the floor</p>
                        <p className="riddle-line">Look even further below</p>
                    </p>
                    <p>
                        <p className="riddle-line">Locate three colors</p>
                        <p className="riddle-line">That signify your kind</p>
                        <p className="riddle-line">Then move them aside</p>
                        <p className="riddle-line">And rummage behind</p>
                    </p>
                    <p>
                        <p className="riddle-line">If you still cannot find me</p>
                        <p className="riddle-line">I’m surrounded by liquid</p>
                        <p className="riddle-line">And for any young people</p>
                        <p className="riddle-line">This place is restricted</p>
                    </p>
                </p>
            </div>

            <Link className="link" to="/"><p>{'<<'} Return home</p></Link>

        </div>
    );
};
