/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Link } from 'react-router-dom';
import maxAndDarijaImg from './maxAndDarija.png';
import './Home.css';

export const Home = (): JSX.Element => {
    return (
        <div className="Home">
            <div className="flex-container">

                <div className="left">
                    <img className="maxAndDarijaImg" src={maxAndDarijaImg} alt="I love you Darija!" />
                </div>

                <div className="right">
                    <h1 className="title">Happy 24th Birthday!</h1>
                    <p className="subtitle">I love you!</p>
                    <p>I hope you are having a good day Darija, and I hope you like your gifts.  Even though you already opened them, I still wanted you to have something to open on your birthday.  That's why I hid something in your house... now it's up to you to find it!</p>
                    <p>Love, Max</p>
                    <Link className="link" to="/riddle"><p>Find the last gift {'>>'}</p></Link>
                </div>

            </div>
        </div>
    );
};
