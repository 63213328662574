import React from 'react';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import { Home } from './components/Home/Home';
import { Riddle } from './components/Riddle/Riddle';
import './App.css';

const App = (): JSX.Element => {
    return (
        <div className="App">
            <BrowserRouter>
                <nav>
                    <Link className="navLink" to="/"><h1>Happy Birthday Darija!</h1></Link>
                </nav>
                
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/riddle" component={Riddle} />
                </Switch>
            </BrowserRouter>
        </div>
    );
};

export default App;